.lawArticlesPickerContent {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  height: 100%;
  width: 100%;
}
ul.lawArticlesPickerList {
  height: 100%;
  min-height: 3rem;
  overflow: auto;
}

.lawArticlesPickerSpinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
.lawArticlesPicker {
  height: 3rem;
  width: 3rem;
}
.lawArticlesPickerText {
  padding: 1rem 0 15vh 0;
}

.lawArticlesPickerTrack {
  padding: 0.5rem 1.5rem;
  text-align: left;
  cursor: pointer;
  border: 1px solid transparent;
  transition: all ease-in-out 0.25s;
  user-select: none;
}
.lawArticlesPickerTrack:hover {
  border: 1px solid rgba(var(--secondary-color), 1);
}

.lawArticlesPickerTrackSelected {
  background-color: rgba(var(--primary-color), 0.35);
}

.lawArticlesPickerTrackNotSelected {
  color: gray;
  text-decoration: line-through;
  cursor: not-allowed;
}

.lawArticlesPickerTrackTitle {
  font-weight: bold;
}

.lawArticlesPickerTrackSubtitle {
  font-size: 80%;
  font-style: italic;
  overflow: hidden;
  text-overflow: ellipsis; 
}

.lawArticlesPickerButtonsBar {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.lawArticlesPickerButtonsDeleteAll {
  opacity: 0;
  transition: all ease-in-out 0.25s;
}
.lawArticlesPickerButtonsDeleteAllActive {
  opacity: 1;
}
#loginContainer {
    z-index: 100;
}
.showLogin {
    transform: scale(1, 1);
}
.hideLogin {
    transform: scale(1, 0);
}

div.loginCard {
    z-index: 101;
}

div.loginError {
    transform: scale(0, 0);
    height: 0;
    color: #d23939;
    transition: transform 0.2s linear 0s;
}
div.loginErrorShow {
    height: auto;
    transform: scale(1, 1);
}

button.loginResetPassword {
    color: rgba(var(--primary-color), 1);
    text-decoration: none;
    padding: 0.5rem;
    background: none;
    border: none;
    font-size: inherit;
    cursor: pointer;
}

div.loginLoading {
    color: rgba(var(--primary-color), 1);
    font-style: italic;
}

button.loginSignUpButton {
    margin-top: 2rem;
}
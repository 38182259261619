#signUpContainer {
    z-index: 200;
}
.showSignUp {
    transform: scale(1, 1);
}
.hideSignUp {
    transform: scale(1, 0);
}

div.signUpCard {
    max-height: 100%;
}

div.signUpError {
    transform: scale(0, 0);
    height: 0;
    color: #d23939;
    transition: transform 0.2s linear 0s;
}
div.signUpErrorShow {
    height: auto;
    transform: scale(1, 1);
}

form.signUpFormContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 30rem;
    overflow: auto;
}

div.signUpOkLogo {
    font-size: 500%;
    padding: 0;
}

div.signUpSuccessText {
    padding: 0;
    color: rgba(var(--primary-color), 1);
}

div.signUpLegal {
    color: rgba(255, 255, 255, 0.50);
    font-size: 75%;
    max-width: 45rem;
}
#lawTextContent .eurLex .inline-element {
    display: inline;
}

#lawTextContent .eurLex .grid-container {
    display: grid;
}

#lawTextContent .eurLex .grid-list {
    grid-template-columns: minmax(3rem, auto) 1fr;
}

#lawTextContent .eurLex div.norm {
    margin: 1rem 0;
}

#lawTextContent .eurLex p.norm, #lawTextContent .eurLex div.list {
    margin: 0.5rem 0;
}


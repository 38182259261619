.cardsCardContainer {
  display: flex;
  flex-direction: column;

  padding: 2rem 2rem 1rem 2rem;
  border-radius: 10px;

  height: 100%;
  width: 100%;

  box-sizing: border-box;
}

.cardsCardInputWrapper {
  position: relative;
  margin: 0;
  white-space: nowrap;
}

/*.cardsCardInputWrapper:last-child {
  margin-right: 0;
}*/

.cardsCardInputHelper {
  min-width: 4rem;
  display: inline-block;
  opacity: 0;
}

.cardsCardInput {
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
  background: none;
  border: none;
  border-bottom: 1px solid rgba(var(--secondary-color), 0.6);
  outline: none;

  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  z-index: 1;

  transition: all ease-in-out 0.15s;
}

.cardsCardInput:focus {
  border-bottom: 3px solid rgba(var(--primary-color), 1);
  border-radius: 3px;
}

.cardsCardInputResolvedOk {
  border-radius: 3px;
  border-bottom: 3px solid green;
}

.cardsCardInputResolvedKo {
  border-radius: 3px;
  border-bottom: 3px solid red;
}

.cardsCardButtonResolve {
  all: unset;
  position: fixed;
  top: 0;
  right: 0;
  cursor: pointer;
  transition: all ease-in-out 0.5s;
  opacity: 0;
  transform: scale(0, 0);
}

.cardsCardInput:focus + .cardsCardButtonResolve {
  transform: scale(1, 1);
  opacity: 1;
}

.cardsCardOrigin {
  padding-top: 1rem;
  font-size: 80%;
  font-style: italic;
  text-align: right;
  margin-top: auto;
}

.cardsCardButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
}

.cardsCardButtonIcon {
  all: unset;
  cursor: pointer;
  transition: all ease-in-out 0.25s;
}

button.cardsCardButton {
  background: none;
  color: rgba(var(--primary-color), 1);
  box-shadow: inset 0 -0.6rem 0 -0.35rem rgba(var(--primary-color), 1);
  padding: 0.5rem;
}

.cardsCardIcon {
  fill: rgba(var(--primary-color), 1);
  padding: 0.5rem;
  height: 1.4rem;
  width: auto;
}

.cardsCardButtonHidden {
  opacity: 0;
  cursor: initial;
}
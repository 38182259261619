#playerPlaylistContainer {
    min-width: min(30%, 45rem);
    max-width: min(90%, 45rem);
    height: 100%;
    right: 0;
    bottom: 0;
    background-color: rgb(var(--secondary-color));
    color: rgb(var(--primary-color));
    position: absolute;
    z-index: 89;
    transition: transform var(--menu-transition-time) linear 0s;
    transform-origin: right;
    box-sizing: border-box;
}
div.playerPlaylistHide {
    transform: scale(0, 1);
}

/* Common styles for PlayerPlaylist modules (law, custom, etc.) */
a.track {
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    padding: 0.75rem 1rem;
    color: inherit;
    text-decoration: inherit;
    text-align: right;
    cursor: pointer;
}
a.track:hover {
    background-color: rgba(var(--primary-color), 0.2);
}
a.track:visited {
    color: rgb(var(--primary-color-visited-link));
}
a.currentTrack {
    color: ghostwhite !important;
    background-color: rgba(var(--primary-color), 0.4);
}

div.playerPlaylistLoading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-style: italic;
}
div.playerPlaylistLoadingSpinner {
    margin: 1rem;
    width: 2.5rem;
    height: 2.5rem;
}

#cardsContainer {
  width: 100%;
  height: 100%;
  overflow: auto;
}

#cardsContent {
  width: 100%;
  max-width: 35rem;
  margin: auto;
  text-align: center;
  margin-top: 20vh;

  transition: all ease-in-out 0.5s;
}

.openPickerContainer {
  display: flex;
  max-width: 20rem;
  margin: 2rem auto 0 auto;
  gap: 1rem;
}

.openPickerButton {
  flex: 1 1 0;
}

.cardsLoading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;
  height: 100%;
  gap: 2rem;
}

.cardsLoadingSpinner {
  height: 3rem;
  width: 3rem;
}

.cardsCarousel {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: -10vh 0 0 0;
}

.cardsCarouserCardWrapper {
  position: absolute;
  width: 40rem;
  max-width: 90vw;

  max-height: 80vh;
  overflow: auto;

  border-radius: 10px;
  background-color: rgba(var(--card-background-color), 1);
  filter: brightness(67%);
  /*rgba(var(--primary-color), 0.25);*/
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

  transform: scale(0.85, 0.85);

  transition: all ease-out 0.5s, opacity cubic-bezier(.95,.05,.8,.04) 0.5s;
}

.cardActive {
  transform: scale(1, 1);
  filter: brightness(100%);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  z-index: 100;
}

.cardSkip {
  transform: rotate(60deg) translate(-10%, -100%) scale(1.2, 1.2);
  transform-origin: bottom right;
  filter: brightness(100%);
  translate: 10px, 10px;
  opacity: 0;
  z-index: 130;
  pointer-events: none;
}

.cardResolving {
  transform: rotate(-60deg) translate(-10%, -100%) scale(1.2, 1.2);
  transform-origin: bottom left;
  filter: brightness(100%);
  translate: 10px, 10px;
  opacity: 0;
  z-index: 130;
  pointer-events: none;
}

.cardDestroy {
  transform: rotate(-100deg) translate(-100%, 0%) scale(0.25, 0.25);
  transform-origin: center center;
  filter: brightness(100%);
  translate: 10px, 10px;
  opacity: 0;
  z-index: 130;
  pointer-events: none;
}

.cardsDisclaimer {
  margin-top: 2rem;
  font-size: 80%;
  font-style: italic;
}

.cardsDone {
  width: 100%;
  max-width: 35rem;
  margin: auto;
  text-align: center;

  transition: all ease-in-out 0.5s;
}
div.playerPdfViewerContainer {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.playerPdfViewerLoadingSpinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.playerPdfViewerLoadingSpinner{
  margin: 1rem;
  width: 2.5rem;
  height: 2.5rem;
}
div.customContentEditorContainer {
    position: fixed;

    display: flex;
    flex-direction: column;

    width: 100%;
    height: calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    top: 0;
    left: 0;
    border-radius: 25px;

    margin: env(safe-area-inset-top) 0 env(safe-area-inset-bottom) 0;

    background-color: rgba(var(--secondary-color), 1);

    z-index: 999;

    transition: border-radius 0.5s linear 0s, transform 0.5s linear 0s;
    transform: scale(0, 0);
}
div.customContentEditorContainerShow {
    transform: scale(1, 1);
    border-radius: 0;
}

input.customContentEditorTitle {
    margin-left: 1rem;
    margin-right: 1rem;
}

div.customContentEditorTinyContainer {
    height: 100%;
    padding: 0 1rem;
}

div.customContentEditorButtonbar{
    display: flex;
    justify-content: space-evenly;
    padding: 1rem 0.5rem;
}

div.customContentEditorLoadingSpinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;

    background-color: rgba(255, 255, 255, 0.90);
    border-radius: 1px;

    z-index: 1000;
}
div.customContentEditorLoadingSpinner {
    margin: 1rem;
    width: 2.5rem;
    height: 2.5rem;
}
button.customContentEditorLoadingSpinnerCancel {
    margin-top: 1rem;
}
.myAccountIcon {
  width: 100%;
  height: 100%;
  max-height: 7rem;
  max-width: 7rem;

  margin: 0 auto;

  fill: rgba(var(--secondary-color), 1);
}

div.myAccountData {
  padding-top: 5rem;
  margin: 0 auto;
}

span.myAccountLabel {
  font-weight: bold;
  padding-right: 0.75rem;
}
/*span.myAccountValue {}*/

div.myAccountActions {
  display: flex;
  flex-direction: column;

  padding-top: 5rem;
  margin: 0 auto;
}

button.myAccountActionsButton {
  display: flex;
}

.myAccountActionsButtonIcon {
  margin-right: 0.5rem;
  fill: rgba(var(--primary-color), 1);
  max-width: 1.5rem;
}

button.myAccountActionsDeleteButton {
  color: red;
  margin-top: 1.5rem;
  font-style: italic;
}
.myAccountActionsButtonIconDelete {
  fill: red;
}
#playerLawPlaylist {
    display: flex;
    flex-direction: column;
    height: 100%;
}

ul.lawPlaylistList {
    height: 100%;
    overflow: auto;
    padding: 0;
    margin: 0;
    list-style-type: none;
}

span.lawTextParagraph {
    display: block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    white-space: pre-line;
    font-size: 1.1rem;
    line-height: 1.5;
    text-indent: 1.5rem;
}

button.playerPlaylistLawContentBackButton {
    margin: 0.5rem;
}

a.lawPlaylistListTrack.lawPlaylistListTrack {
    text-align: left;
}
div.lawPlaylistListTrackContainer {
}
div.lawPlaylistListTrackTitle {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
div.lawPlaylistListTrackSubtitle {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-style: italic;

    font-size: 75%;
}
.lawPlayerReportContainer {
    position: absolute;
    right: 6px;
    top: 50%;

    background-color: white;
    box-shadow: 0 0 6px #B2B2B2;
    border-radius: 1rem;

    display: flex;
    justify-content: center;
    align-items: center;

    max-width: 15rem;

    padding: 0.5rem;
    cursor: pointer;
}
.lawPlayerReportContainer:hover .lawPlayerReportText {
    max-width: 100%;
    max-height: 100%;
    padding-left: 0.5rem;
}
.lawPlayerReportText {
    transition: all 0.25s linear;
    max-width: 0;
    max-height: 0;
    overflow: hidden;
    font-size: 70%;
    white-space: nowrap;
}

.lawPlayerReportIcon {
    flex: 1 0 auto;
    fill: rgba(var(--primary-color), 1);
    height: 1rem;
    width: 1rem;
}
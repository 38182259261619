#navBar {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    background-color: rgb(var(--secondary-color));
    height: var(--nav-Bar-height);
}

.navBarLogoContainer {
    width: auto;
    height: var(--nav-Bar-height);
    box-sizing: border-box;
    padding: 0.5rem;
}
.navBarLogoIcon {
    max-height: 100%;
    max-width: 100%;
}

.navBarAppLogo {
    fill: rgb(var(--primary-color));
    width: auto;
    height: var(--nav-Bar-height);
    box-sizing: border-box;
    padding: 0.5rem;
}

.navBarPlaylistLogo {
    fill: rgb(var(--primary-color));
    width: auto;
    height: var(--nav-Bar-height);
    box-sizing: border-box;
    padding: 0.5rem;
    z-index: 90;
    cursor: pointer;
    transition: all var(--menu-transition-time) linear 0s;
}
.navBarPlaylistLogoActive {
    transform: rotateY(180deg);
}
.navBarPlaylistLogoHidden {
    transform: scale(0,0) rotateZ(360deg);
}

div.navBarMenuToggle {
    cursor: pointer;
    margin: 0.5rem;
    top: 0;
    left: 0;
    z-index: 92;
}
div.navBarMenuButtonBar1, div.navBarMenuButtonBar2, div.navBarMenuButtonBar3 {
    width: 35px;
    height: 5px;
    background-color: rgb(var(--primary-color));
    margin: 6px 0;
    transition: var(--menu-transition-time);
}
div.navBarMenuShow div.navBarMenuButtonBar1 {
    transform: rotate(-45deg) translate(-9px, 6px);
}
div.navBarMenuShow div.navBarMenuButtonBar2 {opacity: 0;}
div.navBarMenuShow div.navBarMenuButtonBar3 {
    transform: rotate(45deg) translate(-9px, -6px);
}
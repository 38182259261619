#lawTextContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    height: 100%;
}

#lawTextName {
    font-size: 2.3rem;
    margin: 0;
    padding: 1rem;
    text-align: center;
}

#lawTextSubtitle {
    font-size: 1rem;
    margin: 0;
    padding: 0 1rem 1rem 1rem;
    font-style: italic;
    text-align: center;
}

#lawTextContent {
    max-width: 50rem;
    padding: 0 0.5rem;
}
div.modalOptionsContainer {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom));
  width: 100%;
  top: 0;
  left: 0;

  margin: env(safe-area-inset-top) 0 env(safe-area-inset-bottom) 0;

  background-color: rgba(0, 0, 0, 0.8);

  transition: background-color 250ms linear;

  z-index: 9999;
}
div.modalOptionsContainerHide {
  visibility: hidden;
  background-color: transparent;
}

div.modalOptionsBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 10vh;
  min-width: 20vw;
  max-width: 40rem;
  padding: 2rem;
  background-color: white;
  border-radius: 15px;

  transition: opacity 250ms linear;
}
div.modalOptionsBoxHide {
  opacity: 0;
}

div.modalOptionsMessage {
  text-align: center;
}

div.modalOptionsButtonBar {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  padding-top: 2rem;
}

button.modalOptionsButton {
  width: 100%;
  max-width: 6rem;
}
span.tooltippedTextContainer {
    position: relative;
    border-bottom: 1px dashed rgba(var(--primary-color));
    font-style: italic;
    cursor: pointer;
}

.tooltippedTextContainer:hover .tooltippedTextTooltip {
    opacity: 1;
    visibility: visible;
}

span.tooltippedTextTooltip {
    position: absolute;
    width: 10rem;
    background-color: rgba(85, 85, 85, 0.95);
    color: #fff;
    text-align: center;
    padding: 0.75rem;
    border-radius: 6px;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    transition: opacity 200ms;

    bottom: 125%;
    left: 50%;
    margin-left: -5rem;
}
span.tooltippedTextTooltip:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;

    box-sizing: inherit;
}


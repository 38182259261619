#updatePasswordContainer {
    z-index: 200;
}
div.showUpdatePassword {
    transform: scale(1, 1);
}
div.hideUpdatePassword {
    transform: scale(1, 0);
}

div.updatePasswordCard {
    z-index: 201;
}

.updatePasswordFormContainer {
    padding: 0;
}

div.updatePasswordError {
    color: #d23939;
    transition: transform 0.2s linear 0s, height 0.1s linear 0s;
    transform: scale(0, 0);
    height: 0;
}
div.updatePasswordErrorShow {
    height: auto;
    transform: scale(1, 1);
}

div.updatePasswordLoading {
    color: rgba(var(--primary-color), 1);
    font-style: italic;
}

div.updatePasswordNotification {
    display: flex;
    flex-direction: column;
    align-items: center;
}
div.updatePasswordNotificationMsg {
    padding-bottom: 1rem;
}
div.updatePasswordSuccess {
    color: rgba(var(--primary-color), 1);
}
div.updatePasswordExpired {
    color: #d23939;
}

button.updatePasswordBackButton {
    margin-top: 2rem;
}
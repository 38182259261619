div.menuBackground {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0);
    z-index: 90;
    transform: scale(0, 0);
    transition: background-color var(--menu-transition-time) linear 0s;
}
div.menuBackgroundShown {
    background-color: rgba(0, 0, 0, 0.6);
}
div.menuBackgroundHidden {
    transform-origin: left;
    transition: transform var(--menu-transition-time) linear 0s;
}


div.menuContainer {
    width: fit-content;
    min-width: 15rem;
    max-width: 45rem;
    height: 100%;
    background-color: rgb(var(--secondary-color));
    color: rgb(var(--primary-color));
    padding: var(--nav-Bar-height) 0 0 0;
    box-sizing: border-box;
    transition: transform var(--menu-transition-time) linear 0s;
    transform-origin: left;
}

div.showMenu {
    transform: scale(1, 1);
}
div.hideMenu {
    transform: scale(0, 1);
}

ul.menuButtonList {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    padding: 0;
    margin: 0;
    list-style-type: none;
}

li.menuButtonListItem {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0.5rem 1rem 0.5rem 0.5rem;
    font-size: 110%;
    font-weight: bold;
    font-variant: small-caps;
    letter-spacing: 0.1rem;
}
li.menuButtonListItem:hover {
    background-color: rgba(var(--primary-color), 0.2);
}
li.menuButtonListItemBottom {
    margin-top: auto;
}

li.section {
    font-variant: small-caps;
    padding: 0.5rem 1rem 0.5rem 0.5rem;
    font-weight: bold;
    letter-spacing: 5px;
    margin-top: 1.5rem;
    font-style: italic;
}
li.section:first-child {
    margin-top: 0;
}

.sectionIcon {
    fill: rgba(var(--primary-color), 1);
    padding: 0.5rem;
    height: 1.2rem;
    margin: 0 0.2rem;
    width: auto;
}
.modalContainer {
    display: flex;

    position: fixed;
    top: 0;
    left: 0;
    height: calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    width: 100%;

    margin: env(safe-area-inset-top) 0 env(safe-area-inset-bottom) 0;

    background-color: rgba(0, 0, 0, 0.7);

    color: black;

    z-index: 95;
}
.modalContainerDisabled {
    transform: scale(0, 0);
}
.modalContainerCard {
    display: flex;
    flex-direction: column;
    position: relative;

    margin: auto;
    transition: transform 0.3s linear;

    height: 50rem;
    width: 50rem;
    max-height: 100%;
    max-width: 100%;

    background-color: white;
    border-radius: 15px;

    padding: 1.5rem;
    box-sizing: border-box;
    overflow: auto;
}
.modalContainerCardDisabled {
    transform: scale(0, 0);
}
.modalTitle {
    padding: 0 0 1.5rem 0;
    text-align: center;
    font-weight: bolder;
    font-size: 160%;
}
.modalClose {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 150%;
    font-weight: bolder;
    margin: 1.5rem;
    cursor: pointer;
    user-select: none;
    z-index: 96;
}
.lawPlayerContainer {
    display: flex;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    height: 100%;
    backdrop-filter: invert(0);
}

#lawTextContainer {
    flex: 1 1 auto;
}
#logoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.logoLabel {
    fill: white;
    max-width: min(90%, 18rem);
    height: auto;
    margin-top: 1rem;
}
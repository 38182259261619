#passwordRestoreContainer {
    z-index: 200;
}
.showPasswordRestore {
    transform: scale(1, 1);
}
.hidePasswordRestore {
    transform: scale(1, 0);
}

div.passwordRestoreCard {
   z-index: 201;
}

div.passwordRestoreError {
    color: #d23939;
    transition: transform 0.2s linear 0s;
    transform: scale(0, 0);
    height: 0;
}
div.passwordRestoreErrorShow {
    height: auto;
    transform: scale(1, 1);
}

div.passwordRestoreLoading {
    color: rgba(var(--primary-color), 1);
    font-style: italic;
}

div.passwordRestoreSuccess {
    color: rgba(var(--primary-color), 1);
}

button.passwordRestoreBackButton {
    margin-top: 2rem;
}
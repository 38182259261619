#playerCustomPlaylist {
    height: 100%;
    max-width: 45rem;
}

div.customPlaylistListContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
}

ul.customPlaylistList {
    height: 100%;
    overflow: auto;
    padding: 0;
    margin: 0;
    list-style-type: none;
}

li.customTrackListSectionLabel{
    padding: 0.75rem 0 0 0;
    font-variant: all-small-caps;
    font-size: 110%;
    text-align: center;
    cursor: pointer;
    user-select: none;
}

a.customTrackItemLink.customTrackItemLink {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 0;
}

button.customTrackMenuButton {
    position: relative;
    margin: 0;
    padding: 0 0.75rem;
    background: none;
    border: none;
    cursor: pointer;
    outline: none;

    transition: opacity 100ms linear;

    opacity: 0.2;
}
a.track:hover button.customTrackMenuButton, button.customTrackMenuButton:focus {
    opacity: 1;
}
.customTrackMenuIcon {
    height: 1.7rem;
    width: 1.7rem;
    fill: rgba(var(--primary-color));

    transition: transform 100ms linear;
}
.customTrackMenuIcon:hover {
    transform: scale(1.3, 1.3);
}

div.customTrackMenu {
    position: absolute;

    transition: transform 100ms linear;
    transform-origin: 0 0;
    transform: scale(0.0, 0.0);

    z-index: 2;
}
button.customTrackMenuButton:focus div.customTrackMenu {
    transform: scale(1.0, 1.0);
}
ul.customTrackMenuList {
    background-color: white;
    padding: 0.2rem 0;
    border-radius: 5px;

    color: black;

    min-width: 12rem;

    text-align: left;
}
li.customTrackMenuListEntry {
    display: flex;
    align-items: center;

    padding: 0.7rem;
    margin: 0.4rem 0;
}
li.customTrackMenuListEntry:hover {
    background-color: rgba(var(--primary-color), 0.75);
    color: white;
}
.customTrackMenuListEntryIcon {
    height: 1.25rem;
    margin-right: 0.25rem;
}
li.customTrackMenuListEntry:hover .customTrackMenuListEntryIcon {
    fill: white;
}


div.customTrackItemLinkText {
    width: 100%;
}
div.customTrackTitle {

}
div.customTrackState {
    font-size: 80%;
    font-style: italic;
}

div.playlistCustomPlaceholder {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    padding: 1rem;
    direction: ltr;
    text-align: center;
}

.playlistCustomSadIcon {
    fill: rgb(var(--primary-color));
    height: 7rem;
    width: 7rem;
}
span.playlistCustomSadIconText {
    font-size: 175%;
    font-weight: bold;
    padding-bottom: 1rem;
}

button.playlistCustomPlaceholderButton {
    margin: 1rem 0.5rem 0.5rem 0.5rem;
}

button.playerPlaylistCustomAddButton {
    margin: 0.5rem;
}

div.playerPlaylistCustomFreeCredits {
    margin-top: 0.5rem;
    font-style: italic;
    font-size: 80%;
    text-align: center;
}
.classic {
    --primary-color: 0, 163, 221;
    --secondary-color: 19, 37, 84;
    --primary-color-visited-link: 8, 113, 166;
    --card-background-color: 217, 237, 255;
}

.chocoleyes {
    --primary-color: 221, 89, 0;
    --secondary-color: 68, 29, 2;
    --primary-color-visited-link: 171, 89, 0;
    --card-background-color: 247, 213, 191;
}

.darkMode {
    filter: invert(0.8);
}
div.darkModeBackground { 
    backdrop-filter: invert(0.8);
}
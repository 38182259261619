#playerPlaylistLawContainer {
    height: 100%;
    max-width: 45rem;
}

div.playerPlaylistLawListContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
}
ul.playerPlaylistLawList {
    height: 100%;
    overflow: auto;
    padding: 0;
    margin: 0;
    list-style-type: none;
}

button.playerPlaylistLawLawButton {
    width: 100%;
    text-align: left;
    text-indent: -1.5rem;
    color: inherit;
    padding: 0.75rem 1rem 1rem 2.5rem;
    border: none;
    cursor: pointer;
    background-color: inherit;
}
button.playerPlaylistLawLawButton:hover {
    background-color: rgba(var(--primary-color), 0.2);
}

button.playerPlaylistLawAddLawButton {
    margin: 0.5rem;
}
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(./assets/fonts/Montserrat-Thin.woff2) format('woff2');
}
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./assets/fonts/Montserrat-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./assets/fonts/Open-Sans-400.woff2) format('woff2');
}

:root {
  --nav-Bar-height: 3.5rem;

  --menu-transition-time: 0.25s;
  --player-color: 134, 134, 134;

  --ok-color: 200, 230, 201;
  --warn-color: 255, 183, 77;
  --error-color: 239, 154, 154;
}

html {
  touch-action: none;
  font-family: 'Open Sans', sans-serif;
}

html, body {
  height: 100%;
  overflow: hidden;
  font-size: 16px;
}

body {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;

  /* This breaks multi-theme compatibility, but is needed for safe-inset-* until App padding is fixed */
  /* Also, keep in mind that this feature is also broken with the meta tag "theme-color" in the index.html */
  background-color: #441D02;
}

div#root {
  touch-action: none;
  height: 100%;
}

iframe {
  border: none;
}

a {
  text-decoration: none;
  color: rgba(var(--primary-color), 1);
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li {
  padding: 0;
  margin: 0;
}

input, textarea {
  padding: 0.7rem;
  margin: 0.7rem;
  font-family: inherit;
  font-size: inherit;
}
input::placeholder, textarea::placeholder {
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  color: rgba(0, 0, 0, 0.5)
}

.fullScreenModal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgb(var(--secondary-color));
  transition: transform 0.2s linear 0s;
  transform-origin: top;
}
.centeredCard {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  max-height: 70rem;
  max-width: 70rem;
  text-align: center;
  padding: 3rem;
  border-radius: 7px;
}
input.cardInput {
  padding: 0.7rem;
  margin: 0.7rem;
  width: 20rem;
}
input.cardInput::placeholder {
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  color: rgba(0, 0, 0, 0.5)
}
div.cardMessage {
  padding: 0.5rem;
  max-width: 40rem;
}

button.rcl, input.rcl {
  display: inline-block;
  padding: 0.7rem 1.4rem;
  margin: 0 0.3rem 0.3rem 0;
  border-radius: 3px;
  box-sizing: border-box;
  text-decoration: none;
  font-family: Montserrat, sans-serif;
  font-variant: small-caps;
  font-weight: 400;
  font-size: 100%;
  color: #FFFFFF;
  outline: none;
  text-align: center;
  position: relative;
  border: none;
  cursor: pointer;
  letter-spacing: 0.2rem;
  background-color: rgba(var(--primary-color), 1);
  box-shadow: inset 0 -0.6rem 0 -0.35rem rgba(var(--secondary-color), 0.15);
  appearance: none;
}
button.rcl:active, input.rcl:active {
  top: 0.1rem;
  box-shadow: none;
}
button.rclSecondary, input.rclSecondary {
  background: none;
  color: rgba(var(--primary-color), 1);
  font-weight: 400;
  font-size: 110%;
  box-shadow: none;
}
button.rclSecondaryDark, input.rclSecondaryDark {
  background-color: #b0b0b0;
}

.Toastify__toast {
  margin-top: env(safe-area-inset-top);
}
.playbackIcon {
    fill: rgb(var(--player-color));
    cursor: pointer;
}

.playbackIconBackground {
    transform: scale(0.9, 0.9);
    margin: 0 0.4rem;
}

.playbackIconActive {
    fill: rgb(var(--primary-color));
}

span.playbackRateLabel {
    color: rgb(var(--player-color));
    user-select: none;
    width: 3rem;
    text-align: center;
}
span.playbackRateLabel::before {
    content: 'x';
}

.rhap_container {
    outline: 0;
}

.rhap_time {
    color: rgb(var(--player-color));
}

.rhap_button-clear{
    color: rgb(var(--player-color));
}
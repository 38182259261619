.spinner {
    border-radius: 100%;
    height: 100%;
    width: 100%;
    border-top: 2px solid rgba(var(--primary-color), 1);
    border-left: 2px solid rgba(var(--primary-color), 0.1);
    border-bottom: 2px solid rgba(var(--primary-color), 0.1);
    border-right: 2px solid rgba(var(--primary-color), 0.1);
    animation: spinnerInfiniteRotation 2s infinite linear;
}
@keyframes spinnerInfiniteRotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.spinnerDefaultContainer {
    margin: auto;
    height: 2rem;
    width: 2rem;
}
div.App {
    display: flex;
    position: fixed;
    width: 100%;
    height: calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    flex-direction: column;
    overflow: hidden;

    background-color: white;
    margin: env(safe-area-inset-top) 0 env(safe-area-inset-bottom) 0;
}
.lawPurchaseContent{
    display: flex;
    flex-direction: column;
    overflow: hidden;

    height: 100%;
    width: 100%;
}
ul.lawPurchaseList {
    height: 100%;
    min-height: 3rem;
    overflow: auto;
}
.lawPurchaserLawListButton {
    text-align: left;

    width: 100%;

    border: 1px solid rgb(236, 236, 236);
    background-color: rgb(248, 248, 248);

    margin: 0.1rem 0;
    padding: 0.75rem 0.5rem;
    box-sizing: border-box;

    cursor: pointer;

    color: black;

    transition: box-shadow 0.25s linear;
}
.lawPurchaserLawListButton:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0 0 15px;
    position: relative;
}

.lawPurchaserLawDate {
    padding-top: 0.25rem;
    font-style: italic;
    font-size: 80%;
}

input.lawPurchaseInputFilter {
    width: 100%;
    box-sizing: border-box;
    margin: 0.5rem 0 0 0;
}

.lawPurchaseMsgContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}
.purchasedLawDone {
    text-align: center;
    padding-bottom: 8rem;
}
.okLogo {
    padding-top: 7vh;
    color: green;
    font-size: 800%;
    font-weight: bolder;
    user-select: none;
    margin: auto;
}
.koIcon {
    fill: darkorange;
    color: red;
    width: 6rem;
    height: 6rem;
    user-select: none;
    margin: auto;
}
.purchasedLawDoneMsg {
    font-size: 120%;
}
button.purchasedLawPurchaseMore {
    display: block;
    margin: 1rem auto auto auto;
}
.purchasedLawSpinner {
    height: 3rem;
    width: 3rem;
}
.purchasedLawDoneSpinner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.purchasedLawSpinnerText {
    padding: 1rem 0 15vh 0;
}

li.lawPurchaserContact {
    text-align: center;
    font-size: 80%;
    padding: 0.5rem 0 0.5rem 0;
    font-style: italic;
}
button.lawPurchaserOpenContact {
    display: inline;
    padding: 0;
    margin: 0;
    letter-spacing: 0;
    font-size: inherit;
    font-style: italic;
}

div.purchasedLawPurchaseMessage {
    display: block;
    margin: auto;
    max-width: 30rem;
    text-align: center;
}